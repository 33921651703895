<template>
    <div class="broadBandInstall">
        <van-nav-bar class="navbar" title="宽带" @click-left="leftReturn" fixed left-arrow/>
        <!-- 搜索栏-->
        <div class="search">
            <div class="search-icon"></div>
            <input type="text" placeholder="合同编号/房源地址/租客姓名、手机号">
        </div>
        <!--拨打电话弹窗-->
        <dial-mobile :ownerMobile="temporaryOwnerMobile" :userName="temporaryOwnerName" :dialogTitle="dialogTitle" :mobileShow="isMobileShow" @hideDialog="hideDialog">
        </dial-mobile>
        <div class="reviews">
            <div class="review" v-for="(item,index) in infoList" :key="index">
                <div :class="item.repairStatus=='待验收'?'top-title-green':item.repairStatus=='待处理'?'top-title-blue':item.repairStatus=='待安装'?'top-title-orange':''">
                    {{item.repairStatus}}
                </div>
                <div class="position">
                    <span class="position-span-right">{{item.roomAddress}}</span>
                </div>

                <div class="tel_IMg" @click="showMobile({row:item})">
                    <img class="tel" src="../../../assets/images/tel.png">
                </div>
                <div style="float: left;width: 100%;border:0.5px solid #f8f8f8;margin:10px 0 10px 0"></div>
                <div @click="viewDetail" class="reject">
                    <div class="textTime">{{item.repairTime}}</div>
                    <div class="text">品牌：<span class="sp">{{item.brand}}</span></div>
                    <div class="text">联系人：<span class="sp">{{item.contact}}</span></div>
                    <div class="text">维修描述：<span class="sp">{{item.repairDes}}</span></div>
                </div>
                <div style="float: left;width: 100%;border:0.5px solid #f8f8f8;margin: 0 0 10px 0"></div>
                <div>
                    <div class="bottom_iconDiv">
                        <div class="icon" :class="item.number != 2 ? 'trashCanHide':''">
                        </div>
                        <div class="icon" :class="item.number != 3 ? 'trashCanHide':''" @click="pwdShow = true">
                            <img class="pwd_IMG"  src="../../../assets/images/RepairPending-getPwd.png">
                            <span class="getPwd">获取密码</span>
                        </div>
                    </div>
                </div>
                <!--                验收完成按钮-->
                <!--<div>
                    <button @click="acceptedClick" :class="item.number != 1?'trashCanHide':''" class="btn">{{finishDispatch}}</button>
                </div>-->
                <!--                退回、完成按钮-->
                <div>
                    <button class="cancelBtn" :class="item.number != 3?'trashCanHide':''">{{back}}</button>
                    <button @click="finished"  :class="item.number != 3?'trashCanHide':''"  class="btn">{{finish}}</button>
                </div>
            </div>

        </div>
        <!--获取密码-->
        <van-overlay :show="pwdShow" @click="show = false">
            <div class="wrapper" @click.stop>
                <div class="block" >
                    <!--  获取密码的div-->
                    <div class="getPassword">
                        获取密码
                    </div>
                    <!--  门锁密码展示的DIV-->
                    <div class="LockPassWord">
                        <p class="password">{{doorPassWord}} </p>
                    </div>
                </div>
                <div class="closeImg" @click="closeMobileModel">
                </div>
            </div>
        </van-overlay>
        <!--        验收弹窗-->
        <van-popup class="van-popup--bottom" v-model="acceptedShow" position="bottom" :style="{ height: '80%' }" >
            <img style="float: left;margin: 20px 5px 0 20px;width: 15px;height: 15px;" src="../../../assets/images/colon.png">
            <div style="margin-top: 18px;margin-bottom: 0;font-size: 15px;font-weight: bold" >验收</div>
            <div class="normalBlock" >
                <span class="maker" :class="{'orange':(isPass||isReject)}">• </span>
                <span class="blockTitle">验收结果</span>
                <div class="rightText">
                    <span class="passText" :class="{'choosedStyle':isPass}" @click="choosePass">通过</span>
                    <span class="content-divide" > | </span>
                    <span class="rejectText" :class="{'choosedStyle':isReject}" @click="chooseReject">驳回</span>
                </div>
            </div>
            <div class="normalBlock" >
                <span :class="value == ''?'maker':'orange'" >• </span>
                <span class="blockTitle">施工负责人评价</span>
                <div class="star">
                    <van-rate v-model="value" :count="5" />
                </div>
            </div>
            <!--验收备注-->
            <div class="row2">
                <div class="acceptedRemake">验收备注</div>
                <textarea placeholder="输入取消原因" class="remake"/>

                <van-uploader class="uploadDiv" v-model="fileList" >
                    <div class="upload">
                        <img src="../../../assets/images/grayAdd.png" class="upload_icon">
                        <div class="upload_font">上传图片</div>
                    </div>
                </van-uploader>
            </div>
            <van-button @click="acceptedBtn" :class="!isPass&&!isReject  || !value> 1 ?'saveDisable':'saveEnable'"
                        :disabled=" !isPass&&!isReject  || !value">确定</van-button>
        </van-popup>

    </div>

</template>

<script>
    import {Button, DropdownItem, DropdownMenu, NavBar, Popup, Search, Uploader, Rate, Overlay,} from "vant";
    import dialMobile from '../../../components/rongxun/rx-dialMobile/dialMobile'
    export default {
        components: {
            [NavBar .name]:NavBar,
            [Search .name]:Search,
            [DropdownMenu .name]:DropdownMenu,
            [DropdownItem .name]:DropdownItem,
            [Popup.name]: Popup,
            [Button.name]: Button,
            [Uploader.name]: Uploader,
            [Rate .name]: Rate ,
            dialMobile,
            [Overlay .name]: Overlay ,
        },
        name: "broadbandInstall",
        data(){
            return{
                //获取密码
                pwdShow: false,
                doorPassWord:'45787812',
                //拨打电话弹窗 start
                isMobileShow:false,
                temporaryOwnerMobile:'',
                temporaryOwnerName:'',
                dialogTitle:'联系业主',
                //拨打电话弹窗 end
                show:false,
                fileList: [
                    { url: 'https://img.yzcdn.cn/vant/leaf.jpg' },
                    // Uploader 根据文件后缀来判断是否为图片文件
                    // 如果图片 URL 中不包含类型信息，可以添加 isImage 标记来声明
                    { url: 'https://cloud-image', isImage: true },
                ],
                value:1,
                finishDispatch:'验收完成',
                back:'退回',
                finish:'完成',

                isPass: false,
                isReject: false,
                expectedDate: '',
                cancelShow:false,
                repairShow:false,
                acceptedShow:false,
                //所有状态列表
                infoList:[
                    {
                        number:1,
                        repairTime:'2020-20-01',
                        ownerName:'黎明1',
                        ownerMobile:'13244561236',
                        brand:'联通',
                        repairDes:'这里坏了哪里坏了，前方都坏了这里坏了哪里坏了，前方都坏了',
                        repairStatus:'待验收',
                        roomAddress: '华南区黑街家和花样年华一期-职工街100号1单元',
                    },

                    {
                        number:2,
                        repairTime:'2020-20-01',
                        ownerName:'黎明2',
                        ownerMobile:'13244561236',
                        brand:'联通',
                        repairDes:'这里坏了哪里坏了，前方都坏了这里坏了哪里坏了，前方都坏了',
                        repairStatus:'待安装',
                        roomAddress:'华南区黑街家和花样年华一期-职工街100号1单元',
                    },
                    {
                        number:3,
                        repairTime:'2020-20-01',
                        ownerName:'黎明3',
                        ownerMobile:'13244561236',
                        brand:'联通',
                        repairDes:'这里坏了哪里坏了，前方都坏了这里坏了哪里坏了，前方都坏了',
                        repairStatus: '待处理',
                        roomAddress: '华南区黑街家和花样年华一期-职工街100号1单元',
                    },

                ],
            }

        },
        methods:{

            viewDetail() {
              this.$router.push({
                  name: 'broadBandDetails',
                  query: {

                  }
              })
            },

            finished() {
              this.$router.push({
                  name: 'BroadbandCompletion',
                  query: {

                  }
              })
            },

            //关闭密码弹窗
            closeMobileModel(){
                // this.$emit('hideDialog');
                this.pwdShow = false
            },
            //拨打电话
            showMobile(e){
                let that = this;
                that.temporaryOwnerMobile = e.row.ownerMobile;
                that.temporaryOwnerName = e.row.ownerName
                that.isMobileShow = !that.isMobileShow;
            },
            //关闭拨打电话弹窗
            hideDialog() {
                let that = this;
                that.isMobileShow = false;
            },
            //返回上一页
            leftReturn() {
                this.$router.go(-1);
            },
            // 弹框按钮--点击触发弹框--确认后弹框消失
            cancelClick(){
                this.cancelShow = true
            },
            cancelBtn(){
                this.cancelShow = false
            },
            acceptedClick(){
                this.acceptedShow = true
            },
            acceptedBtn(){
                this.acceptedShow = false
            },
            repairClick(){
                this.repairShow = true
            },
            repairBtn(){
                this.repairShow = false
            },
            // 通过、驳回按钮
            choosePass() {
                this.isPass = true;
                this.isReject = false;
            },
            chooseReject() {
                this.isReject = true;
                this.isPass = false;

            },
        }
    }
</script>

<style lang="less" scoped>
    .broadBandInstall{
        .van-overlay{
            background-color: rgba(80,80,80,.7);;
        }
    }
    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .block {
        width: 76%;
        height: 21.5%;
        background-color: #fff;
        border-radius: 8px;
        overflow: hidden;
    }
    /*    获取密码DIV*/
    .getPassword{
        background: url("../../../assets/images/ContactPerson-img.png") no-repeat center center,
        linear-gradient(to right,#FFC274 ,#FF5D3B);
        text-align: center;
        height: 50px;
        line-height: 53px;
        color: white;
        background-size: 100%;
        font-size: 16px;
    }
    /*    门锁密码*/
    .LockPassWord{
        margin-top: 20px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        line-height: 14px;
    }

    /*传入的密码值*/
    .password{
        color: #ff8247;
        font-size: 20px;
        margin-top: 40px;
        font-family: SimSun;
    }
    /*关闭按钮*/
    .closeImg{
        margin-top: 100px;
        height: 30px;
        width: 30px;
        border: 1px solid white;
        border-radius: 50%;
        background: url("../../../assets/images/GetDoorPassword-closeImg.png") no-repeat center center;
        background-size:18px 18px;
    }
    /*弹窗样式*/
    .van-popup--bottom{
        /*z-index: 2004;*/
        background-color: #f8f8f8;
    }

    /*验收备注Div*/
    .row2{
        float: left;
        position: relative;
        flex-direction: row;
        align-items: center;
        background-color: white;
        width: 345px;
        height: 240px;
        border-radius: 8px;
        padding-bottom: 10px;
        margin: 15px 5px 15px 15px;
    }
    /*验收备注*/
    .acceptedRemake{
        margin: 10px 0 8px 15px;
        font-size: 14px;
        font-weight: bold;
    }
    .blockTitle{
        float: left;
        font-size: 14px;
        font-weight: bold;
        width: 35%;
    }
    .blockTitle{
        float: left;
        margin-right: 25px;
        font-size: 14px;
        font-weight: bold;
        width: 35%;
    }
    .rightText{
        margin-left:auto;
        font-size: 14px;
        color: #999999;
    }
    .star{
        margin-left:8px;
        font-size: 14px;
        color: #999999;
    }
    .choosedStyle{
        color: #ff3c00;
        font-weight: bold;
    }
    .passText{
        flex: 1;
        padding-right: 7px;
    }
    .rejectText {
        padding-left: 7px;
        padding-right: 33px;
    }
    .normalBlock{
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        position: relative;
        background-color: white;
        line-height: 1rem;
        width: 345px;
        height: 50px;
        border-radius: 8px;
        margin: 10px 15px 0 10px;
    }

    /*备注输入框*/
    .remake{
        color: gray;
        width: 310px;
        border-radius: 10px;
        line-height: 20px;
        height: 70px;
        margin: 5px 10px 0 10px;
        background-color: #f8f8f8;
        font-size: 12px;
        border: none;
        padding-left: 10px;
        padding-top: 10px;
    }
    /*上传图片*/
    .uploadDiv{
        display: flex;
        margin: 10px 0 0 10px;
        height: 70px;
        width: 345px;
        background: url("../../../assets/images/grayAdd.png")30px 30px;
    }
    .upload{
        border-radius: 10px;
        height: 80px;
        width: 80px;
        background-color: #f8f8f8;
        margin-left: 10px;
        float: left;
    }
    .upload_icon{
        height: 35px;
        width: 35px;
        margin:10px 10px 2px 21px;
    }
    .upload_font{
        height: 25px;
        font-size: 12px;
        line-height: 2px;
        margin-left: 14px;
        color: gray;
    }
    .part-inputpart-row input{
        background-color: white;
        width: 100%;
    }

    /*圆点样式---灰色*/
    .maker{
        color: #999999;
        float: left;
        font-size: 28px;
        margin: 8px 10px 10px 22px;
    }
    /*圆点样式---橘色*/
    .orange{
        color: #ff5809;
        float: left;
        font-size: 28px;
        margin: 8px 10px 10px 22px;
    }

    input{
        font-size: 14px;
        margin-left: 15px;
        background-color: #f4f4f4;
        border: 0;
        width: 250px;
    }
    input::-webkit-input-placeholder{
        color: #d9d9d9;
    }
    .icon{
        float: left;
        width: 150px;
        height: 30px;
        margin-top: 5px;
    }
    /*竖线样式*/
    .content-divide {
        font-size: 14px;
        color: #efefef;
        margin:0 5px 0 5px;
    }
    /*保存按钮不可点击样式*/
    .saveButton_Disable{
        /*background: linear-gradient(to right, rgba(184, 184, 184, 0.2), #B8B8B8 20%) repeat scroll 0% 0%;*/
        background-color: rgba(184, 184, 184, 0.2);
        width: 345px;
        height: 50px;
        border-radius: 8px;
        margin:40px 50px 15px 15px;
        font-size: 18px;
        color: white;
        line-height: 1.22rem;
        text-align: center;
    }
    .saveButton_Enable{
        background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
        color: white;
        width: 345px;
        height: 50px;
        border-radius: 8px;
        margin:40px 50px 15px 15px;
        font-size: 18px;
        line-height: 1.22rem;
        text-align: center;
    }
    .saveDisable{
        /*background: linear-gradient(to right, rgba(184, 184, 184, 0.2), #B8B8B8 20%) repeat scroll 0% 0%;*/
        background-color: rgba(184, 184, 184, 0.2);
        width: 345px;
        height: 50px;
        border-radius: 8px;
        margin:50px 50px 15px 15px;
        font-size: 18px;
        color: white;
        line-height: 1.22rem;
        text-align: center;
    }
    .saveEnable{
        background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
        color: white;
        width: 345px;
        height: 50px;
        border-radius: 8px;
        margin:50px 50px 15px 15px;
        font-size: 18px;
        line-height: 1.22rem;
        text-align: center;
    }
    textarea{
        resize: none;
        color: gray;
        font-size: 12px;
        width: 320px;
        height: 80px;
        background: #f0f0f0;
        margin-left: 15px;
        border-radius: 10px;
        padding: 10px 0 0 10px;
        border: none;
    }
    .navbar{
        z-index: 10;
        background-color: #f8f8f8;
    }
    .search{
        margin: 60px 20px 0 20px;
        height: 35px;
        border-radius: 30px;
        background-color: #f4f4f4;
        display: flex;
        align-items: center;
    }
    .search-icon{
        width: 25px;
        height: 25px;
        margin-left: 10px;
        background-image: url("../../../assets/images/search-icon.png");
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    .bottom_iconDiv{
        float: left;
        width: 155px;
        height: 30px;
    }
    .tel_IMg{
        float: left;
        width: 20px;
        height: 20px;
        margin-right: 20px;
        line-height: 24px;
    }
    .tel{
        width: 19px;
        height: 18px;
    }
    .pwd_IMG{
        float: left;
        width: 30px;
        height: 25px;
        margin-left: 15px;
        margin-top: 3px;
    }

    .reviews{
        margin-top: 15px;
        width: 345px;
        height: 260px;
    }
    .review{
        margin: 0 15px 15px 15px;
        width: 345px;
        height: 260px;
        background-color: white;
        border-radius: 8px;
    }
    .cancelBtn{
        float: left;
        height: 30px;
        width: 80px;
        margin-top: 5px;
        font-size: 14px;
        /*line-height: 31px;*/
        margin-right: 5px;
        border: 1px solid transparent;
        border-radius: 10px;
        background-image: linear-gradient(white,white),
        linear-gradient(to right,#ffc274,#ff5D3B);
        background-clip: padding-box ,border-box;
        background-origin: border-box;
        color: #ff5809;
        text-align: center;
    }
    .btn{
        float: right;
        margin-right: 20px;
        margin-top: 5px;
        height: 30px;
        width: 80px;
        font-size: 14px;
        /*line-height: 31px;*/
        border: none;
        border-radius: 10px;
        background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
        text-align: center;
        color: white;
    }
    .top-title-green{
        /*margin-bottom: 4px;*/
        width: 38px;
        height: 15px;
        font-size: 10px;
        text-align: center;
        line-height: 18px;
        text-align: center;
        border-radius: 6px 0;
        background: linear-gradient(to right,#63cb96,#30d0ac);
        color: white;
    }
    .top-title-orange{
        /*margin-bottom: 4px;*/
        width: 38px;
        height: 15px;
        font-size: 10px;
        text-align: center;
        line-height: 19px;
        border-radius: 6px 0;
        background: linear-gradient(to right,#ffbe72,#ff6c41);
        color: white;
    }
    .top-title-blue{
        width: 38px;
        height: 15px;
        font-size: 10px;
        text-align: center;
        line-height: 18px;
        border-radius: 6px 0;
        background: linear-gradient(to right,#7BC7FF,#3B98FF);
        color: white;
    }
    .getPwd{
        font-size: 14px;
        float: left;
        color: #9a9a9a;
        margin-top: 6px;
    }
    .position{
        margin: 5px 15px 0 15px;
        height: 42px;
        text-align: left;
        font-size: 14px;
        font-weight: bold;
        width: 275px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        float: left;
    }
    .position-span-right{
        color: black;
        font-size: 15px;
    }



    .reject{
        float: left;
        width: 315px;
        height: 110px;
        margin-left: 15px;
    }
    .sp{
        color: #9a9a9a;
        font-size: 13px;
        font-weight: normal;
    }
    .text{
        color: black;
        font-size: 14px;
        font-weight: bold;
    }
    .textTime{
        color: #ff5809;
        font-size: 14px;
        font-weight: bold;
    }
    .trashCanHide{
        display: none;
    }
    .trashCanShow{
        display: block;
    }

</style>
